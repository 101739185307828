import React, {Component} from 'react';
import './Home.sass';
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {login, logout, registration, setFlashError} from "../../services/user/actions";
import secretID from "../../services/secretID";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toAccount: false
        };

        this.props.setFlashError(null);

        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount() {
        document.title = "Neos Account";
    }


    handleLogin(event) {
        event.preventDefault();
        this.props.setFlashError(null);
        const user = new FormData(event.target);

        if (!secretID.get()) {
            secretID.generate();
        }

        const data = {
            Password: user.get("password"),
            SecretMachineId: secretID.get(),
        };

        const credential = user.get("credential");

        if (credential.startsWith("U-"))
            data.OwnerId = credential;
        else if (credential.includes("@"))
            data.Email = credential;
        else
            data.Username = credential;


        this.props.login(data);
    }

    render() {
        const {logged, flashError} = this.props;
        if (logged) {
            return <Redirect to='/account'/>;
        }

        return (
            <div className="Home">
                <form onSubmit={this.handleLogin} className="form">
                    {flashError && (
                        <p className="text-danger">{flashError}</p>
                    )}

                    <div className="form-group">
                        <label>Username / E-mail:</label>
                        <input type="text" name="credential" className="form-control"
                               required="required"/>
                    </div>
                    <div className="form-group">
                        <label>Password:</label>
                        <input type="password" name="password" className="form-control"
                               required="required"/>
                    </div>
                    <button type="submit" className="btn btn-primary">Login</button>
                </form>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        userSession: state.user.userSession,
        flashError: state.user.flashError
    }
};

export default connect(mapStateToProps, {
    registration, login, logout, setFlashError
})(Home);
