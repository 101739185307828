import React, {Component} from 'react';
import './OAuth2.sass';
import {connect} from "react-redux";
import queryString from 'query-string';
import {login, setFlashError} from "../../services/user/actions";
import secretID from "../../services/secretID";

class OAuth2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            params: queryString.parse(this.props.location.search)
        };

        this.props.setFlashError(null);

        this.handleLogin = this.handleLogin.bind(this);
    }

    handleLogin(event) {
        event.preventDefault();
        this.props.setFlashError(null);
        const user = new FormData(event.target);

        if (!secretID.get()) {
            secretID.generate();
        }

        const data = {
            Password: user.get("password"),
            SecretMachineId: secretID.get(),
        };

        const credential = user.get("credential");

        if (credential.startsWith("U-"))
            data.OwnerId = credential;
        else if (credential.includes("@"))
            data.Email = credential;
        else
            data.Username = credential;


        this.props.login(data);
    }

    render() {
        const {user, logged, flashError} = this.props;

        if (!("redirect_uri" in this.state.params)) {
            return (
                <div>
                    Redirect URI not defined.
                </div>
            );
        }

        if (logged) {
            let redirect_uri = "/api/oauth2/token.php"
                + "?&user=" + this.props.userSession.userId
                + "&token=" + this.props.userSession.token
                + "&redirect_uri=" + this.state.params.redirect_uri;

            return (
                <div>
                    <div className="OAuth2__continue">
                        <img src={user.avatar} alt={user.username}/>
                        <p className="OAuth2__continue__name">{user.username}</p>

                        <a href={redirect_uri} className="OAuth2__continue__button button">Continue
                        </a>

                        <p><a href="/" onClick={() => window.close()}>Cancel</a></p>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <form onSubmit={this.handleLogin} className="form">
                    {flashError && (
                        <p className="text-danger">{flashError}</p>
                    )}

                    <div className="form-group">
                        <label>Username / E-mail:</label>
                        <input type="text" name="credential" className="form-control"
                               required="required"/>
                    </div>
                    <div className="form-group">
                        <label>Password:</label>
                        <input type="password" name="password" className="form-control"
                               required="required"/>
                    </div>
                    <button type="submit" className="btn btn-primary">Login</button>
                </form>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        userSession: state.user.userSession,
        flashError: state.user.flashError
    }
};

export default connect(mapStateToProps, {login, setFlashError})(OAuth2);
