import {USER} from "./actionTypes";
import API from "../../api";
import neos from "../neos";

export const registration = user => (
    (dispatch) => (
        API.registration(user)
            .then((data) => (
                true
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const registrationLog = user => (
    (dispatch) => (
        API.registrationLog(user)
            .then((data) => (
                true
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const login = user => (
    (dispatch) => (
        API.login(user)
            .then((userSession) => (
                dispatch(receiveUserSession(userSession, dispatch))
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const logout = userSession => (
    (dispatch) => {
        API.logout(userSession).catch(function (error) {
            //dispatch(setFlashError(error.toString()));
        });

        dispatch({
            type: USER.LOGOUT
        });
    }
);

export const receiveUserSession = (userSession, dispatch) => {
    API.getUser(userSession)
        .then((user) => (
            dispatch(receiveUser(user))
        )).catch(function (error) {
        dispatch(setFlashError(error.toString()));
    });
    return {
        type: USER.RECIVE_SESSION,
        payload: {
            userSession: userSession
        }
    };
};

export const refreshUser = userSession => (
    (dispatch) => (
        API.getUser(userSession)
            .then((user) => (
                dispatch(receiveUser(user))
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const receiveUser = user => {
    user.avatar = neos.getAvatar(user);

    return {
        type: USER.RECIVE,
        payload: {
            user: user
        }
    }
};

export const loadInventory = userSession => (
    (dispatch) => (
        API.getInventory(userSession)
            .then((inventory) => (
                dispatch({
                    type: USER.RECIVE_INVENTORY,
                    payload: {
                        inventory: inventory
                    }
                })
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const loadInventoryOwnerDirectory = (userSession, id, owner) => (
    (dispatch) => (
        API.getInventoryOwnerDirectory(userSession, id, owner)
            .then((inventoryDirectory) => (
                dispatch({
                    type: USER.RECIVE_INVENTORY_DIRECTORY,
                    payload: {
                        inventoryDirectoryActualPath: id,
                        inventoryDirectory: inventoryDirectory
                    }
                })
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const loadInventoryLink = (userSession, id, owner) => (
    (dispatch) => (
        API.getInventoryItem(userSession, id, owner)
            .then((inventoryItem) => (
                API.getInventoryOwnerDirectory(userSession, inventoryItem.name, owner)
                    .then((inventoryDirectory) => (
                        dispatch({
                            type: USER.RECIVE_INVENTORY_DIRECTORY,
                            payload: {
                                inventoryDirectoryActualPath: inventoryItem.name,
                                inventoryDirectory: inventoryDirectory
                            }
                        })
                    )).catch(function (error) {
                    dispatch(setFlashError(error.toString()));
                })
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const loadFriends = userSession => (
    (dispatch) => (
        API.getFriends(userSession)
            .then((friends) => (
                dispatch({
                    type: USER.RECIVE_FRIENDS,
                    payload: {
                        friends: friends
                    }
                })
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const loadMessages = (userSession, choosedFriend) => (
    (dispatch) => (
        API.getMessages(userSession, choosedFriend)
            .then((messages) => (
                dispatch({
                    type: USER.RECIVE_MESSAGES,
                    payload: {
                        messages: messages
                    }
                })
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const sendMessage = (userSession, choosedFriend, content) => (
    (dispatch) => {
        dispatch({
            type: USER.SEND_START_MESSAGE
        });

        return API.sendMessage(userSession, choosedFriend, content)
            .then((messages) => {
                dispatch({
                    type: USER.SEND_END_MESSAGE
                });

                return dispatch({
                    type: USER.RECIVE_MESSAGES,
                    payload: {
                        messages: messages
                    }
                })
            }).catch(function (error) {
                dispatch({
                    type: USER.SEND_END_MESSAGE
                });

                dispatch(setFlashError(error.toString()));
            });
    }
);

export const resetInventoryDirectory = () => ({
    type: USER.RESET_INVENTORY_DIRECTORY
});

export const setFlashError = error => ({
    type: USER.FLASH_ERROR,
    payload: {
        error: error
    }
});

export const setChoosedFriend = friend => ({
    type: USER.CHOOSE_FRIEND,
    payload: {
        friend: friend
    }
});