import React, {Component} from 'react';
import ReactModal from 'react-modal';
import {connect} from 'react-redux';
import {registration,registrationLog, login, logout, setFlashError} from '../../services/user/actions';
import {Redirect} from "react-router-dom";
import secretID from "../../services/secretID";
import ReCAPTCHA from "react-google-recaptcha";

class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoginModal: false,
            showRegistrationModal: false,
            showAfterRegistrationModal: false,
            isRobot: true,

            toHome: false
        };


        this.handleOpenAfterRegistration = this.handleOpenAfterRegistration.bind(this);
        this.handleCloseAfterRegistration = this.handleCloseAfterRegistration.bind(this);

        this.handleOpenRegistration = this.handleOpenRegistration.bind(this);
        this.handleCloseRegistration = this.handleCloseRegistration.bind(this);
        this.handleRegistration = this.handleRegistration.bind(this);
        this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
        this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);

        this.handleOpenLogin = this.handleOpenLogin.bind(this);
        this.handleCloseLogin = this.handleCloseLogin.bind(this);

        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    onChangeRecaptcha(value) {
        this.setState({isRobot: false});
    }

    onErrorRecaptcha(value) {
        this.setState({isRobot: true});
    }

    handleOpenAfterRegistration(e) {
        this.props.setFlashError(null);
        this.setState({showAfterRegistrationModal: true});

        e.preventDefault();
        return false
    }

    handleCloseAfterRegistration() {
        this.setState({showAfterRegistrationModal: false});
    }

    handleOpenRegistration(e) {
        this.props.setFlashError(null);
        this.setState({showRegistrationModal: true});

        e.preventDefault();
        return false
    }

    handleCloseRegistration() {
        this.setState({showRegistrationModal: false});
    }

    handleRegistration(event) {
        event.preventDefault();
        this.props.setFlashError(null);
        let _self = this;
        const user = new FormData(event.target);

        if (this.state.isRobot) {
            this.props.setFlashError("Are you really not a robot?");
            return;
        }

        if (!secretID.get()) {
            secretID.generate();
        }

        const data = {
            Username: user.get("username"),
            Email: user.get("email"),
            Password: user.get("password"),
        };

        this.props.registration(data).then(function () {
            if (!_self.props.flashError) {
                _self.props.registrationLog(data);
                _self.handleCloseRegistration();
                _self.handleOpenAfterRegistration(event);
            }
        });
    }

    handleOpenLogin(e) {
        this.props.setFlashError(null);
        this.setState({showLoginModal: true});

        e.preventDefault();
        return false
    }

    handleCloseLogin() {
        this.setState({showLoginModal: false});
    }

    handleLogin(event) {
        event.preventDefault();
        this.props.setFlashError(null);
        let _self = this;
        const user = new FormData(event.target);

        if (!secretID.get()) {
            secretID.generate();
        }

        const data = {
            Password: user.get("password"),
            SecretMachineId: secretID.get(),
        };

        const credential = user.get("credential");

        if (credential.startsWith("U-"))
            data.OwnerId = credential;
        else if (credential.includes("@"))
            data.Email = credential;
        else
            data.Username = credential;


        this.props.login(data).then(function () {
            if (!_self.props.flashError) {
                _self.handleCloseLogin();
            }
        });
    }

    handleLogout(event) {
        const {userSession} = this.props;
        event.preventDefault();
        this.props.logout(userSession);
        // RTH  this.setState({toHome: true});
    }

    toHomeToFalse() {
        // Callback not call re-render
        this.setState({toHome: false});
    }

    render() {
        const {user, logged, flashError} = this.props;

        if (this.state.toHome === true) {
            this.toHomeToFalse();
            this.props.setFlashError(null);
            return <Redirect to='/'/>;
        }

        if (logged) {
            return (
                <div>
                    <div className="dropdown">
                        <span className="dropdown-span">
                            <img src={user.avatar} alt={user.username}/>
                            {user.username}
                        </span>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" href="/" onClick={this.handleLogout}>Logout</a>
                        </div>
                    </div>

                </div>
            );
        }

        return (
            <div>
                {/*<a href="/" onClick={this.handleOpenLogin}>Sign In</a>*/}    
                <a href="/" className="App-nav-link-button" onClick={this.handleOpenRegistration}>Register</a>


                <ReactModal
                    isOpen={this.state.showAfterRegistrationModal}
                    onRequestClose={this.handleCloseAfterRegistration}
                    ariaHideApp={false}
                    style={{content: {background: "none", border: "none"}, overlay: {background: "rgba(0,0,0,.6)"}}}
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <p className="text-success">
                                    Registration was successful. Check your email to verify account.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                        onClick={this.handleCloseAfterRegistration}>Close
                                </button>
                            </div>
                        </div>
                    </div>
                </ReactModal>

                <ReactModal
                    isOpen={this.state.showRegistrationModal}
                    onRequestClose={this.handleCloseRegistration}
                    ariaHideApp={false}
                    style={{content: {background: "none", border: "none"}, overlay: {background: "rgba(0,0,0,.6)"}}}
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <form onSubmit={this.handleRegistration} className="form">
                                <div className="modal-header">
                                    <h5 className="modal-title">Registration</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                            onClick={this.handleCloseRegistration}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {flashError && (
                                        <p className="text-danger">{flashError}</p>
                                    )}

                                    <div className="form-group">
                                        <label>Username:</label>
                                        <input type="text" name="username" className="form-control"
                                               required="required"/>
                                    </div>
                                    <div className="form-group">
                                        <label>E-mail:</label>
                                        <input type="email" name="email" className="form-control" required="required"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Password:</label>
                                        <input type="password" name="password" className="form-control"
                                               required="required"/>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"
                                               required="required"/>
                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                            By creating an account you agree to our&nbsp;

                                            <a href="http://wiki.neosvr.com/index.php?title=NeosVR_Wiki:Privacy_policy"
                                               rel="noopener noreferrer"
                                               target="_blank">privacy policy</a>
                                        </label>
                                    </div>

                                    <ReCAPTCHA
                                        sitekey="6LeL0MIUAAAAAHPI8Vw4I6m8iQ4uLAXFPX4dywmF"
                                        onExpired={this.onErrorRecaptcha}
                                        onErrored={this.onErrorRecaptcha}
                                        onChange={this.onChangeRecaptcha}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="float-left btn btn-primary">Register</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                            onClick={this.handleCloseRegistration}>Close
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ReactModal>


                <ReactModal
                    isOpen={this.state.showLoginModal}
                    onRequestClose={this.handleCloseLogin}
                    ariaHideApp={false}
                    style={{content: {background: "none", border: "none"}, overlay: {background: "rgba(0,0,0,.6)"}}}
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <form onSubmit={this.handleLogin} className="form">
                                <div className="modal-header">
                                    <h5 className="modal-title">Login</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                            onClick={this.handleCloseLogin}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {flashError && (
                                        <p className="text-danger">{flashError}</p>
                                    )}

                                    <div className="form-group">
                                        <label>Username / E-mail:</label>
                                        <input type="text" name="credential" className="form-control"
                                               required="required"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Password:</label>
                                        <input type="password" name="password" className="form-control"
                                               required="required"/>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="float-left btn btn-primary">Login</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                            onClick={this.handleCloseLogin}>Close
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        userSession: state.user.userSession,
        flashError: state.user.flashError
    }
};

export default connect(mapStateToProps, {
    registration, login, logout, setFlashError,registrationLog
})(User);