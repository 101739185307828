import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class InventoryLink extends Component {

    render() {
        const {data} = this.props;

        const tmp = data.assetUri.split("/");
        const id = tmp[tmp.length-1];
        const owner = tmp[tmp.length - 2];

        return (
            <div className="InventoryItem InventoryItem--folder">
                <h3 className="InventoryItem-title">
                    <NavLink to={"/inventory/link/" + owner + "/" + id}>
                        {data.name}
                    </NavLink>
                </h3>
            </div>
        );
    }
}

export default InventoryLink;