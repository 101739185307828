import {loaderIncrease, loaderDecrease} from "./services/loader/actions";
import store from "./services/store"

//const USE_CDN = false;

//const NEOS_API = CLOUDX_NEOS_API;
//const NEOS_BLOB = NEOS_CLOUD_BLOB;
//const NEOS_ASSETS = NEOS_BLOB + "assets/";
//const NEOS_THUMBNAILS = NEOS_BLOB + "thumbnails/";
//const NEOS_INSTALL = NEOS_BLOB + "install/";

const CLOUDX_NEOS_API = "https://cloudx.azurewebsites.net";
//const CLOUDX_NEOS_BLOB = "https://cloudxstorage.blob.core.windows.net/";
//const CLOUDX_NEOS_CDN = "https://cloudx.azureedge.net/"; // CDN

//const NEOS_CLOUD_BLOB = USE_CDN ? CLOUDX_NEOS_CDN : CLOUDX_NEOS_BLOB;

const API = {
    jwt: "",

    fetch: function (url, configuration) {
        store.dispatch(loaderIncrease());

        return fetch(url, configuration);
    },

    constructJsonData: function (data) {
        return JSON.stringify(data);
    },

    parseResponse: function (response) {
        store.dispatch(loaderDecrease());

        if (!response.ok) {
            if (!response) {
                throw response.status;
            }

            return response.text().then(function (data) {console.log(data);
                if (data[0] !== "[" && data[0] !== "\"" && data[0] !== "{") {
                    throw data;
                }

                data = JSON.parse(data);

                if ("Message" in data) {
                    throw data.Message;
                }

                throw new Error("Problem with API connection.");
            });
        }
        return response.json();
    },

    registration: (user) => API.fetch(CLOUDX_NEOS_API + "/api/users ", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        cache: 'no-cache',
        body: JSON.stringify(user)
    }).then(response => API.parseResponse(response)),

    registrationLog: (user) => API.fetch("/api/log.php ", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        cache: 'no-cache',
        body: JSON.stringify(user)
    }).then(response => API.parseResponse(response)),

    login: (user) => API.fetch(CLOUDX_NEOS_API + "/api/userSessions", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        cache: 'no-cache',
        body: JSON.stringify(user)
    }).then(response => API.parseResponse(response)),

    logout: (userSession) => API.fetch(CLOUDX_NEOS_API + "/api/userSessions/" + userSession.userId + "/" + userSession.token, {
        method: 'DELETE',
        headers: {
            'Authorization': 'neos ' + userSession.userId + ':' + userSession.token
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    getUser: (userSession) => API.fetch(CLOUDX_NEOS_API + "/api/users/" + userSession.userId, {
        method: 'GET',
        headers: {
            'Authorization': 'neos ' + userSession.userId + ':' + userSession.token
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    getInventory: (userSession) => API.fetch(CLOUDX_NEOS_API + "/api/users/" + userSession.userId + "/records?path=Inventory", {
        method: 'GET',
        headers: {
            'Authorization': 'neos ' + userSession.userId + ':' + userSession.token
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    getInventoryItem: (userSession, id, owner) => API.fetch(CLOUDX_NEOS_API + "/api/users/" + owner + "/records/" + id, {
        method: 'GET',
        headers: {
            'Authorization': 'neos ' + userSession.userId + ':' + userSession.token
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    getInventoryOwnerDirectory: (userSession, id, owner) => API.fetch(CLOUDX_NEOS_API + "/api/users/" + owner + "/records?path=Inventory\\"+encodeURIComponent(id), {
        method: 'GET',
        headers: {
            'Authorization': 'neos ' + userSession.userId + ':' + userSession.token
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    getFriends: (userSession) => API.fetch(CLOUDX_NEOS_API + "/api/users/" + userSession.userId + "/friends", {
        method: 'GET',
        headers: {
            'Authorization': 'neos ' + userSession.userId + ':' + userSession.token
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    getMessages: (userSession, choosedFriend) => API.fetch(CLOUDX_NEOS_API + "/api/users/" + userSession.userId + "/messages?maxItems=100&user=" + choosedFriend, {
        method: 'GET',
        headers: {
            'Authorization': 'neos ' + userSession.userId + ':' + userSession.token
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    sendMessage: (userSession, choosedFriend, content) => API.fetch(CLOUDX_NEOS_API + "/api/users/" + choosedFriend + "/messages", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'neos ' + userSession.userId + ':' + userSession.token
        },
        cache: 'no-cache',
        body: JSON.stringify({
            "MessageType": "text",
            "Content": content
        })
    }).then(response => API.parseResponse(response)),
};

export default API;