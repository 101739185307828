import React, {Component} from 'react';
import neos from "../../../services/neos";

class InventoryItem extends Component {

    render() {
        const {data} = this.props;
        let img = "//via.placeholder.com/350x150?text=none";

        if (data.thumbnailUri) {
            img = neos.neosDbToHttp(data.thumbnailUri);
        }

        let url = null;
        if (data.thumbnailUri) {
            url = neos.neosDbToHttp(data.assetUri);
        }

        return (
            <div className="InventoryItem InventoryItem--file">
                <h3 className="InventoryItem-title">{data.name}</h3>
                {/*<a href={url} target="_blank" rel="noopener noreferrer">*/}
                    <img src={img} alt={data.name} className="responsive-img"/>
                {/*</a>*/}
            </div>
        );
    }
}

export default InventoryItem;