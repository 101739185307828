import React, {Component} from 'react';
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

class InventoryBack extends Component {

    render() {
        return (
            <div className="InventoryItem InventoryItem--folder">
                <h3 className="InventoryItem-title">
                    <span onClick={history.goBack}> &lt; Back</span>
                </h3>
            </div>
        );
    }
}

export default InventoryBack;