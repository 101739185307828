import React, {Component} from 'react';
import { Link } from "react-router-dom";

class NotFound extends Component {

    componentDidMount() {
        document.title = "Stránka nenalezena";
    }

    render() {
        return (
            <div className="NotFound">
                <div className="entry-content">
                    <h2>Stránka nenalezena</h2>
                    <p>
                        Je nám to líto, ale nejspíš nastalo:
                    </p>

                    <ul>
                        <li>Odkaz z <strong>vyhledávače byl starý</strong>.</li>
                        <li>Udělali jsme chybu a odkaz v rámci tohoto webu <strong>je nefunkční</strong>.</li>
                        <li><strong>Odkaz z jiného webu</strong> již není platný.</li>
                    </ul>

                    <p>
                        Můžete ale přejít na <Link to="/">úvodní stránku</Link>.
                    </p>
                </div>
            </div>
        );
    }
}

export default NotFound;
