import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class InventoryOwnerDirectory extends Component {

    render() {
        const {data, inventoryDirectoryActualPath} = this.props;
        const name = encodeURIComponent(encodeURIComponent(data.name));
        const actualPath = encodeURIComponent(encodeURIComponent(inventoryDirectoryActualPath));

        let link = "/inventory/directory/" + data.ownerId + "/" + name;
        if (inventoryDirectoryActualPath && inventoryDirectoryActualPath !== "") {
            link = "/inventory/directory/" + data.ownerId + "/" + actualPath + encodeURIComponent("%5C") + name;
        }

        return (
            <div className="InventoryItem InventoryItem--folder">
                <h3 className="InventoryItem-title">
                    <NavLink to={link}>
                        {data.name}
                    </NavLink>
                </h3>
            </div>
        );
    }
}

export default InventoryOwnerDirectory;