import React, {Component} from 'react';
import InventoryItem from "../components/InventoryItem";
import InventoryLink from "./InventoryLink";
import InventoryBack from "./InventoryBack";
import InventoryOwnerDirectory from "./InventoryOwnerDirectory";

class InventoryList extends Component {
    render() {
        const {inventory, inventoryDirectoryActualPath} = this.props;

        if (!inventory) {
            return <div>Loading...</div>;
        }

        const inventoryDirectories = inventory.filter(item => item.recordType === "directory" && item.name.indexOf("FriendsList") === -1);
        const inventoryLinks = inventory.filter(item => item.recordType === "link");
        const inventoryObjects = inventory.filter(item => item.recordType === "object");

        return (
            <div className="InventoryList">
                <div className="row">
                    {inventoryDirectoryActualPath !== "" && (
                        <div key="b" className="col-6 col-sm-3 col-md-2 text-center mb-5">
                            <InventoryBack/>
                        </div>
                    )}
                    {inventoryDirectories.length > 0 && (
                        inventoryDirectories.map((inventoryItem, index) => (
                            <div key={"d" + index} className="col-6 col-sm-3 col-md-2 text-center mb-5">
                                <InventoryOwnerDirectory data={inventoryItem}
                                                         inventoryDirectoryActualPath={inventoryDirectoryActualPath}/>
                            </div>
                        ))
                    )}

                    {inventoryLinks.length > 0 && (
                        inventoryLinks.map((inventoryItem, index) => (
                            <div key={"l" + index} className="col-6 col-sm-3 col-md-2 text-center mb-5">
                                <InventoryLink data={inventoryItem}/>
                            </div>
                        ))
                    )}

                    {inventoryObjects.length > 0 && (
                        inventoryObjects.map((inventoryItem, index) => (
                            <div key={"f" + index} className="col-6 col-sm-3 col-md-2 text-center mb-5">
                                <InventoryItem data={inventoryItem}/>
                            </div>
                        ))
                    )}
                </div>
            </div>
        );
    }
}

export default InventoryList;
