import React, {Component} from 'react';
import {PieChart, Pie, Tooltip, Cell} from 'recharts';

class BytesChart extends Component {
    parseValue(value) {
        return Math.round(value / 1024 / 1024) * 100 / 100 + "MB"
    }

    render() {
        const {user} = this.props;

        if (!user) {
            return <p>Select user</p>;
        }

        if (!("quotaBytes" in user) || !("usedBytes" in user)) {
            return <p>Broken user</p>;
        }

        if (user.quotaBytes === -1) {
            return <p>not defined</p>;
        }

        const data = [
            {name: 'Used space', value: user.usedBytes},
            {name: 'Free space', value: user.quotaBytes - user.usedBytes}
        ];

        const colors = ["#663399", "#eee"];

        return ([
            <PieChart key={1} width={170} height={170}>
                <Pie startAngle={180} endAngle={-180} dataKey="value" data={data} cx={80} cy={80} innerRadius={40}
                     outerRadius={80} fill="#663399">
                    {
                        data.map((entry, index) => <Cell key={index} fill={colors[index]}/>)
                    }
                </Pie>
                <Tooltip content={<CustomTooltip/>}/>
            </PieChart>,
            <p key={2}>Free space: {this.parseValue(user.quotaBytes - user.usedBytes)}</p>
        ]);
    }
}

class CustomTooltip extends Component {
    parseValue(value) {
        return Math.round(value / 1024 / 1024) * 100 / 100 + "MB"
    }

    render() {
        const {active} = this.props;

        if (active) {
            const {payload} = this.props;

            const style = {
                margin: "0px",
                padding: "10px",
                backgroundColor: "rgb(255, 255, 255)",
                border: "1px solid rgb(204, 204, 204)",
                whiteSpace: "nowrap"
            };

            const value = this.parseValue(payload[0].value);

            return (
                <div className="recharts-tooltip-wrapper">
                    <div className="recharts-default-tooltip" style={style}>
                        <div className="recharts-tooltip-label">{`${payload[0].name}: ${value}`}</div>
                    </div>
                </div>
            );
        }

        return null;
    }
}


export default BytesChart;